import React from 'react';
import IndustryDataTypeTemplate from '@components/IndustryDataType/IndustryDataTypeTemplate';
import { dataTypes, industries } from '@constants/industriesAndDatatypes';
import BackgroundImage from './assets/Earth_Observation_Cover.jpg';
import metaImage from './assets/meta.jpg';

const EarthObservationPage: React.FC = () => {
  return (
    <IndustryDataTypeTemplate
      pageName="industry-earth-observation"
      helmet={{
        title: 'TileDB for Earth Observation',
        description: 'Use TileDB to scale data analytics and machine learning for imaging and weather data.',
        shareImage: {
          url: metaImage,
          width: 1200,
          height: 627,
        },
      }}
      header="Earth Observation"
      title="Scalable data analytics and ML for imaging and weather data"
      heroImage={BackgroundImage}
      icon={industries.earthObservation.icon}
      overview={
        <>
          <p>
            Earth observation data has always been instrumental in government, energy, insurance and finance for use cases such as
            catastrophe monitoring and floodplain analysis. Now, the availability of cloud processing offers the ability to scale and
            deliver insights in real-time.
          </p>
          <p>
            TileDB is a universal, global-scale platform for all earth observation and geospatial data, structuring data types generated by
            SAR, Landsat and formats such as GeoTIFF and NetCDF into time-series cubes. Leading earth observation consumers can now
            eliminate all infrastructure hassles and effort as TileDB is their one-stop shop for datasets, models, dashboards, analytics and
            collaboration.
          </p>
        </>
      }
      relatedItems={[dataTypes.sar, dataTypes.weather, dataTypes.timeSeries, dataTypes.lidar]}
    />
  );
};

export default EarthObservationPage;
